/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { createOrder, getOneOrder, editOrder } from '@/redux/features/orders/ordersSlice';
import { getAllWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { getAllItems } from '@/redux/features/settings/inboundSettings/inboundItemSlice';

const TransshipmentForm = () => {
  const [supplyingStoreOptions, setSupplyingStoreOptions] = useState([]);
  const [receivingStoreOptions, setReceivingStoreOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();

  const { orderItem, loading, createOrderLoading, editOrderLoading } = useSelector((state) => state.orders);
  const { allWarehousesLoading } = useSelector((state) => state.warehouse);

  // fettch values and populate dropdown
  useEffect(() => {
    dispatch(getAllWarehouses())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.store.name,
            value: value._id,
          });
        });
        setSupplyingStoreOptions([{ label: 'Select Store', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getAllWarehouses())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.store.name,
            value: value._id,
          });
        });
        setReceivingStoreOptions([{ label: 'Select Store', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getAllItems())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setProductOptions([{ label: 'Select Product', value: '', isDisabled: true }, ...options]);
      });
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState({
    supplyingStore: '',
    receivingStore: '',
    product: '',
    totalQuantity: '',
    weight: '',
    amount: '',
    type: 'transshipment',
  });

  useEffect(() => {
    if (params.id) {
      dispatch(getOneOrder(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id) {
      setInitialValues({
        supplyingStore: orderItem.supplyingStore._id,
        receivingStore: orderItem.receivingStore._id,
        product: orderItem.product._id,
        totalQuantity: orderItem.totalQuantity,
        weight: orderItem.weight,
        amount: orderItem.amount,
        id: params.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const validationSchema = Yup.object({
    supplyingStore: Yup.string().required('Required'),
    receivingStore: Yup.string().required('Required'),
    product: Yup.string().required('Required'),
    totalQuantity: Yup.number('Not a number').required('Required').positive('Quantity must be greater than zero').integer('Must be an integer'),
    weight: Yup.number('Not a number').required('Required').positive('Weight must be greater than zero').integer('Must be an integer'),
    amount: Yup.number('Not a number').required('Required').positive('Amount must be greater than zero').integer('Must be an integer'),
  });

  const onSubmit = (values, submitProps) => {
    if (params.id) {
      dispatch(editOrder(values));
    } else {
      dispatch(createOrder(values));
    }
    submitProps.resetForm();
  };

  const loadingState = loading || allWarehousesLoading || createOrderLoading || editOrderLoading;

  return (
    <FormTemplate
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={`${params.id ? 'Edit Transshipment Order' : 'Create Transshipment Order'}`}
      submitBtnText={`${params.id ? 'Edit Order' : 'Create Order'}`}
      enableReinitialize
    >
      {loadingState && <Spinner />}
      <div>
        <FormikControl control="select" type="text" label="Supplying Store" name="supplyingStore" placeholder="" options={supplyingStoreOptions} />
      </div>
      <div>
        <FormikControl control="select" type="text" label="Receiving Store" name="receivingStore" placeholder=" " options={receivingStoreOptions} />
      </div>
      <div>
        <FormikControl control="select" type="text" label="Product" name="product" placeholder="" options={productOptions} />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Quantity" name="totalQuantity" placeholder="5670" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Weight(Kg)" name="weight" placeholder="50" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Amount" name="amount" placeholder="5000" />
      </div>
    </FormTemplate>
  );
};

export default TransshipmentForm;
