/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
// import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { createOrder, getOneOrder, editOrder } from '@/redux/features/orders/ordersSlice';
import { getAllWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { getAllSuppliers } from '@/redux/features/settings/inboundSettings/inboundLogisticsSlice';
import { getAllItems } from '@/redux/features/settings/inboundSettings/inboundItemSlice';
import Spinner from '@/components/Spinner';

const PurchaseOrderForm = () => {
  const [storeOptions, setStoreoptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();

  const { orderItem, loading, createOrderLoading, editOrderLoading } = useSelector((state) => state.orders);
  const { allWarehousesLoading } = useSelector((state) => state.warehouse);

  // fettch values and populate dropdown
  useEffect(() => {
    dispatch(getAllWarehouses())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.store.name,
            value: value._id,
          });
        });
        setStoreoptions([{ label: 'Select Store', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getAllSuppliers())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setSupplierOptions([{ label: 'Select Supplier', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getAllItems())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setProductOptions([{ label: 'Select Categories', value: '', isDisabled: true }, ...options]);
      });
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState({
    store: '',
    supplier: '',
    product: '',
    totalQuantity: '',
    weight: '',
    amount: '',
    type: 'purchaseOrder',
  });

  useEffect(() => {
    if (params.id) {
      dispatch(getOneOrder(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id && orderItem) {
      setInitialValues({
        store: orderItem.store._id,
        supplier: orderItem.supplier._id,
        product: orderItem.product._id,
        totalQuantity: orderItem.totalQuantity,
        weight: orderItem.totalQuantity,
        amount: orderItem.amount,
        id: params.id,
      });
    }
  }, [params.id, orderItem]);

  const validationSchema = Yup.object({
    store: Yup.string().required('Required'),
    supplier: Yup.string().required('Required'),
    product: Yup.string().required('Required'),
    totalQuantity: Yup.number('Not a number').required('Required').positive('Quantity must be greater than zero').integer('Must be an integer'),
    weight: Yup.number('Not a number').required('Required').positive('Quantity must be greater than zero').integer('Must be an integer'),
    amount: Yup.number('Not a number').positive('Amount must be greater than zero').required('Required').integer('Must be an integer'),
  });

  const onSubmit = (values, submitProps) => {
    if (params.id) {
      dispatch(editOrder(values));
    } else {
      dispatch(createOrder(values));
    }
    submitProps.resetForm();
  };

  const loadingState = loading || allWarehousesLoading || createOrderLoading || editOrderLoading;

  return (
    <FormTemplate
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={`${params.id ? 'Edit Purchase Order' : 'Create Purchase Order'}`}
      submitBtnText={`${params.id ? 'Edit Order' : 'Create Order'}`}
      enableReinitialize
    >
      {loadingState && <Spinner />}

      <div>
        <FormikControl control="select" type="text" label="Store" name="store" placeholder="" options={storeOptions} />
      </div>
      <div>
        <FormikControl control="select" type="text" label="Supplier/Agent ID" name="supplier" placeholder="" options={supplierOptions} />
      </div>
      <div>
        <FormikControl control="select" type="text" label="Product" name="product" placeholder="Maize" options={productOptions} />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Quantity" name="totalQuantity" placeholder="50" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Weight" name="weight" placeholder="50" />
      </div>
      <div className="mb-6">
        <FormikControl control="input" type="number" label="Amount" name="amount" placeholder="Enter amount" />
      </div>
    </FormTemplate>
  );
};

export default PurchaseOrderForm;
